/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/////////////////////    all the playhigh css starts from here     ////////////////////////////////////

.sc-ion-modal-md-h {
  // width: 80%;
  backdrop-filter: blur(4px) contrast(0.5) !important;
  // border-radius: 0px 0px 80px 0px;
}
.sc-ion-loading-md-h {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.72) !important;
  color: #fff !important;
}
.sc-ion-picker-md-h {
  --background: #000 !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.56) !important;
}
.sc-ion-modal-md-h {
  // --background: transparent !important;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.1) !important;
}
// Start write your common classes here
.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}
.text-left {
  text-align: left !important;
}
.justify-left {
  justify-content: left !important;
}
.justify-right {
  justify-content: right !important;
}
.justify-center {
  justify-content: center !important;
}
.p-0 {
  padding: 0px !important;
}
.pr-0 {
  padding-right: 0px !important;
}
.pl-0 {
  padding-left: 0px !important;
}
ion-content {
  --background: #131313;
}
ion-header {
  background: #131313;
  ion-toolbar {
    padding: 4px;
  }
}
// ion-label,
// ion-title,
// p,
// ion-text,
// span,
// ion-input {
//   font-weight: 600 !important;
// }
.shadow-div {
  height: 50%;
  width: 100%;
  left: 0px;
  position: absolute;
  top: 80px;
  background: linear-gradient(180deg, rgba(19, 19, 19, 0) 0%, #131313 49.48%);
}
.logup {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  h6 {
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    margin: 10px 30px;
  }
  .absol {
    width: 100%;
    max-width: 450px;
    .log-form {
      padding: 10px;
      width: 90%;
      margin: auto;
      line-height: 5px;
      .inside-ele {
        background: #7b7b7b;
        box-shadow: var(--inner-shadow);
        border-radius: 5px;
        margin-bottom: 5px;
        margin-top: 15px;
        .in-ele {
          color: var(--text-color1);
          padding-left: 10px !important;
          --placeholder-color: var(--text-color1);
          font-size: 12px;
          font-weight: 600;
        }
      }
      .eyeIcon {
        font-size: 18px;
      }
      .in-label {
        font-size: 11px;
        line-height: 30px;
        color: var(--text-color1);
        font-weight: 600;
        padding: 16px;
      }
    }
  }
  .new-here {
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    color: var(--text-color1);
    text-align: center;
    margin: 20px 20px;
    b {
      font-size: 15px;
      color: #c2ea50;
    }
  }
  .log-form-btn {
    ion-button {
      margin-top: -30px;
    }
  }
}

.header-flex {
  margin: 0px 5px 0px 10px;
  ion-back-button {
    z-index: 999 !important;
  }
  .img-menu {
    z-index: 999 !important;
    width: 40px;
  }
  .header-title {
    font-size: 11px;
    font-weight: 600;
    text-align: center;
    margin-left: -48px;
    text-transform: uppercase;
    letter-spacing: 3px;
  }

  .head-cent {
    width: 100%;
    text-align: center;
    color: #ffffff;
    margin-top: 5px;
    font-weight: 600;
    margin-left: -70px;
    text-transform: uppercase;
    font-size: 15px;
    margin-left: -45px;
  }
}
.add-cash-modal {
  width: 100%;
  backdrop-filter: blur(5px);
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.1) !important;
  --background: transparent;
  .ion-grid {
    width: 90%;
    margin-top: 70%;
    margin-bottom: 140%;
  }
}
.withdraw-modal {
  width: 100%;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.1) !important;
  --background: transparent;
  .ion-grid {
    width: 90%;
    margin-top: 70%;
    margin-bottom: 140%;
  }
}

.recent-modal {
  width: 100%;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.1) !important;
  --background: transparent;
  height: 44%;
  top: auto;
  box-shadow: none;
}

.recent-modal-full {
  width: 100%;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.1) !important;
  --background: transparent;
  height: 100%;
  top: auto;
  box-shadow: none;
}

.img-home {
  margin-top: 13px;
}
.opacity {
  filter: opacity(0.5);
}
.gradientBorder {
  // this is common class for common outline gradient buttons
  position: absolute;
  background: #fff;
  border-radius: 24px;
}
.gradientBorder2 {
  padding: 23px 54%;
}

.myTeamBtn {
  // this is common class for common outline gradient buttons
  position: relative;
  z-index: 1;
}

.capN {
  margin: 2px 0;
}
.cap {
  color: #f70c5b;
  margin: 0;
}
.players {
  color: #cfd0d0;
}

// Common classes ends here
ion-button {
  height: 10vh;
  width: 90%;
  font-weight: 600;
  text-transform: capitalize;
  --box-shadow: none;
  --border-radius: 25px;
  --color: #fff;
  --ripple-color: transparent;
  --background-hover: transparent !important;
  &::part(native) {
    --background: url(../src/assets/images/app-button.png) no-repeat center;
    background-size: 100%;
  }
}
.btn-submit {
  height: 80px;
  background-size: 100%;
  width: 95%;
  font-weight: 600;
  text-transform: capitalize;
  box-shadow: none;
  border-radius: 100px;
  color: #fff;
  ripple-color: transparent;
  background-hover: var(--background);
  &::part(native) {
    --background: url(../src/assets/images/app-button.png) no-repeat center;
    background-size: 100%;
  }
}
.btn-bg {
  width: 275px !important;
  margin: 0px !important;
  height: 54px !important;
  &::part(native) {
    --background: url(../src/assets/images/app-button.png) no-repeat center;
    background-size: 100%;
  }
}

//Authentication pages css starts
.loginContainer {
  width: 100vw;
  margin: 0 !important;
  // --background: linear-gradient(180deg, #414141 40.31%, #171717 100%);
}

.outerDiv {
  width: 80%;
  height: auto;
  // margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.playerImg {
  position: absolute;
  top: 5vw;
  right: 5vw;
  width: 42px;
  height: auto;
  img {
    width: 100%;
    height: auto;
  }
}

.or {
  color: var(--text-color1);
  font-weight: 600;
  font-size: 18px;
  text-align: center;
}
.orLine {
  margin-top: 15px;
  width: 100%;
  height: 1px;
  border-top: 1px solid #790071;
}

.tagImg {
  position: absolute;
  width: 150px;
  height: auto;
  bottom: 3vw;
  left: 4vw;
  z-index: -1;

  img {
    width: 100%;
    height: auto;
  }
}
.otp {
  // transform: skewY(-30deg);
  position: absolute;
  right: 3vw;
  bottom: 8vw;
  background: var(--ion-color-primary);
  color: var(--text-color1);
  width: 34vw;
  height: 23vw;
  display: flex;
  justify-content: flex-start;
  padding: 0 5px;
  align-items: center;
  border-radius: 3vw;
  letter-spacing: 1.1px;
  line-height: 1.5;
  ion-icon {
    font-size: 24px;
  }
}
.note,
.note2 {
  // font-size: 12px;
  span {
    font-size: 15px;
    line-height: 2;
    color: var(--theme-color);
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: underline;
  }
}
.note {
  color: #000;
}

.error {
  color: #a62c2c;
  margin: 0px 0px -10px 0px;
  font-weight: 600;
  font-size: 11px;
  padding: 0px 12px;
  line-height: 16px;
  // max-width: 275px;
}
.arrowSubmit {
  position: absolute !important;
  bottom: -12vw !important;
  right: 2vw !important;
  width: 80px !important;
  height: 80px !important;
  ion-icon {
    font-size: 30px;
  }
}
//Authentication pages css ends

// dashboard, my-matches header common css starts here
ion-header {
  ion-toolbar {
    .menu {
      width: 40px;
      img {
        width: 100%;
        height: auto;
      }
    }

    .logoHeader {
      top: 0px;
      right: 10px;
      position: absolute;

      img {
        width: 100%;
        height: auto;
      }
    }

    .tabBtn {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 56px;
      width: 16vw;
      padding: 0 5px;
      background: #000;
      margin: 0;

      p {
        margin: 0;
        color: #fff;
        word-wrap: break-word;
        font-size: 10px;
        text-align: center;
        padding-top: 5px;
        letter-spacing: 1px;
      }
    }
  }
}

.activeTab {
  background: linear-gradient(#e86567, #985d83) !important;
}

.contentHeader {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  margin-left: 20px;
}

.matchList {
  box-shadow: none;
  padding: 10px 0;
}

.cardHeader {
  padding: 0;
  height: 25%;
  border-radius: 0px 0px 80px 80px;
  background: rgba(134, 49, 200, 0.44);
  width: 90%;
  margin: 0% 5%;
  ion-row {
    padding: 5px;
    height: 100%;
    ion-col {
      display: flex;
      align-items: center;
    }
  }
}
.matchName {
  width: 70%;
  word-wrap: break-word;
  color: #000;
}
.matchKind {
  justify-content: flex-end;
  text-align: center;
  word-wrap: break-word;
  color: #000;
}
.matchGrid {
  height: auto;
  ion-row {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    ion-col {
      height: auto;
      padding: 0;
      text-align: center;
    }
  }
}
.teamDiv {
  justify-content: flex-end;
}
.teamDiv2 {
  justify-content: flex-start;
}

.teamDiv,
.teamDiv2 {
  display: flex;
  align-items: center;
  height: 100%;

  .teamLogo {
    width: 6vw;
    height: 6vw;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .tName {
    font-size: 12px;
    word-wrap: break-word;
    color: var(--theme-color);
    padding: 0 5px;
    width: 30vw;
  }
  .mRight {
    margin-right: 5px;
    text-align: right;
  }
  .mLeft {
    margin-left: 5px;
    text-align: left;
  }
}

.vs {
  justify-content: center;
  align-items: center;
  div {
    height: 32px;
    background: #1d1c1c;
    border: 1px solid #5a5a5a;
    box-shadow: 1px 2px 2px #000000;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    p {
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 10px;
      height: 0px;
      border-radius: 50%;
      font-size: 25px;
      font-weight: 600;
    }
  }
}

.matchSchedule {
  text-align: center;
  width: 100%;
  padding: 0;
  margin: 0;
  border-top: 1px solid #3c3c3c;
  height: 20%;

  ion-col {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  p {
    color: var(--theme-color);
    margin: 10px 0;
  }

  .timeStatus {
    border-left: 1px solid #3c3c3c;
    border-right: 1px solid #3c3c3c;
  }
}

// dashboard, my-matches header common css ends here

.noMatchesImg {
  width: 50%;
  height: auto;

  img {
    margin: auto;
    display: flex;
    margin-bottom: 16px;
  }
}

// contest, prize-details, my-team, select-your-team, c & vc, team-preview common css starts here
.matchContent {
  height: auto;
  width: 94%;
  margin: auto;
  margin-top: 10px;
  background: transparent;

  .matchGrid {
    height: auto;
    padding: 0;
  }
}

.header-segment-contest {
  // width: 50%;
  // margin: 0% 25%;

  ion-segment-button {
    color: #ffffff;
    --color-checked: var(--ion-color-primary);
    text-transform: capitalize;
    --indicator-height: 0px !important;
    width: 100%;
    --ripple-color: transparent;
    margin: 0px 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.005em;
    margin-top: -10px;
    --color-hover: #fff;
  }
  ion-segment-button.segment-button-checked {
    color: #ffffff;
    margin: 0px 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.005em;
    --color-hover: #fff;
  }

  ion-segment-button.segment-button-checked:after {
    content: "";
    display: block;
    height: 10px;
    background-image: url(../src/assets/images/segline.svg);
    background-repeat: no-repeat;
    background-position: center bottom;
  }
}

.expandedBtn {
  width: 92%;
  margin: 0 4%;
  height: var(--btn-height);
  margin-bottom: 10px;
  --color: var(--text-color1);
}
.outlineBgBtn {
  background: var(--ion-color-primary);
  border-radius: 25px;
}
// .outlineColor {
//   background-image: url('./assets/images/btnOutline.png');
//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: cover;
// }
.btnSolidBg {
  background-image: url("./assets/images/btnSolidBg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

// edit-profile, change-password common css starts here
.formHeader {
  background: var(--l-Gradient);
  padding: 0;
  width: 96%;
  margin: auto;
  border-radius: 25px 25px 0 0;
  color: var(--text-color1);
  margin-top: 15px;
}
.updateProfile-btn {
  height: var(--btn-height);
  margin: 2%;
}
.picker-opt.picker-opt-selected,
.picker-prefix,
.picker-suffix {
  color: #f70c5b;
}

.picker-button.sc-ion-picker-md,
.picker-button.activated.sc-ion-picker-md {
  color: #fff;
}
.picker-button.sc-ion-picker-md,
.sc-ion-picker-md {
  color: #fff;
}

.sc-ion-select-popover-h ion-list-header.sc-ion-select-popover,
.sc-ion-select-popover-h ion-label.sc-ion-select-popover {
  color: #fff;
}

// ion-toolbar {
//   --background: linear-gradient(180deg, #fa296f 0%, #e0004b 100%) !important;
// }
ion-menu {
  --min-width: 100%;
}

.btn-create {
  width: 100%;
  &::part(native) {
    --background: url(../src/assets/images/app_another_button.png) no-repeat
      center;
    background-size: 100%;
  }
}
.btn-submit1 {
  background: url(../src/assets/images/app-button.png) no-repeat center;
  background-size: 100%;
  height: 80px;
  background-size: 100%;
  width: 85%;
  font-weight: 600;
  text-transform: capitalize;
  box-shadow: none;
  border-radius: 100px;
  color: #fff;
}
.btn-team {
  width: 100% !important;
  &::part(native) {
    --background: url(../src/assets/images/app-button1.png) no-repeat center;
    background-size: 100%;
  }
}
//   width: 100% !important;
//   height: 55px;
//   --background: linear-gradient(180deg, #CD8062 0%, #A46145 100%);
// --box-shadow: 1px -1px 2px rgba(94, 94, 94, 0.3), -1px 1px 2px rgba(23, 23, 23, 0.5), inset -1px -1px 2px rgba(81, 49, 36, 0.2), inset 1px 1px 2px rgba(81, 49, 36, 0.2), inset -1px 1px 2px rgba(255, 173, 126, 0.9), inset 1px -1px 3px rgba(81, 49, 36, 0.9);
// --border-radius: 10px;

// }

// .modal-wrapper {
//   // border-radius: 0px 0px 80px 0px !important;
//   // box-shadow: 0px 0px 4px #f70c5b !important;
// }

.sc-ion-alert-md-h {
  --background: #303030 !important;
}

.alert-radio-label.sc-ion-alert-md {
  color: #fff !important;
}

.alert-button-inner.sc-ion-alert-md {
  color: #f70c5b !important;
}

.country-modal {
  z-index: 20002;
  height: 100%;
  margin: auto;
  width: 360px;
  .modal-wrapper.sc-ion-modal-md {
    margin: auto;
    width: 375px;
    height: 80%;
  }
  ion-backdrop {
    --background: transparent !important;
    -webkit-backdrop-filter: blur(2px) !important;
    backdrop-filter: blur(2px) !important;
    --backdrop-opacity: var(--ion-backdrop-opacity, 1) !important;
  }
  ion-header {
    ion-toolbar {
      --background: transparent !important;
    }
  }
}
.loading-wrapper {
  background: transparent !important;
  box-shadow: none !important;
}
.sliderDashboard {
  --bullet-background: #fff !important;
  --bullet-background-active: #c2ea50;
  .swiper-pagination-bullet {
    opacity: 1;
  }
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    box-shadow: 0px 0px 4px 1px #bdff00;
    border-radius: 3px;
    width: 22px;
    height: 7px;
  }
}

// .myacountslide {
//   .swiper-pagination-bullet-active {
//     background: #f8206f;
//     width: 17px;
//     height: 6px;
//     border-radius: 3px;
//   }
//   .swiper-pagination-bullets {
//     bottom: 50px !important;
//   }
//   .swiper-pagination-bullet {
//     background: #868686;
//   }
// }
ion-back-button::part(icon) {
  content: url("../src/assets/images/backbutton.svg") !important;
  width: 15px !important;
  height: 15px !important;
  margin-top: 0px;
}

.menuModalCss {
  backdrop-filter: blur(5px);
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.1) !important;
  &::part(content) {
    opacity: 1;
    background: transparent;
    transform: translateX(-20%);
    --border-radius: 10px;
  }
}

ion-datetime {
  --background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    linear-gradient(169.06deg, #212426 0%, #212426 101.24%) !important;
  font-weight: 600;
}

.calendar-action-buttons ion-item ion-label {
  color: #ffffff;
}
// <--------------------- PWA CSS starts Here ------------------------->

.bg-pwa {
  height: 100%;
  width: 100%;
}
.content {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
  box-sizing: content-box; /* So the width will be 100% + 17px */
  overflow-x: hidden;
}
.toastCss {
  z-index: 60001;
  max-width: 380px;
  font-weight: 600;
  --background: #292c2f;
}
.alertClass {
  font-weight: 600;
  border-radius: 0px;
  background: transparent;
  padding: 10px;
  .alert-title {
    color: #fff;
    font-weight: 600;
  }
  .alert-message {
    color: #fff;
  }
}

@media (min-width: 500px) and (max-width: 1200px) {
  .bg-pwa {
    height: 100%;
    width: 100%;
    background-image: url("./assets/side_bg.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .inner-bg-pwa {
      margin-top: 30px;
      margin-bottom: 30px;
    }
    ion-content {
      width: 400px !important;
      position: absolute;
      top: 50% !important;
      left: 50% !important;
      height: 100% !important;
      transform: translate(-50%, -50%);
      border-radius: 0px 0px 20px 20px;
      --padding-top: 60px;
      --padding-bottom: 60px;
      &::part(background) {
        border-radius: 20px;
      }
      .content {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
        box-sizing: content-box; /* So the width will be 100% + 17px */
      }
    }
    .date-show {
      padding-left: 6vw;
    }
    ion-header {
      width: 400px;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      z-index: 99999;
      border-radius: 20px 20px 0px 0px;
      ion-toolbar {
        border-radius: 20px 20px 0px 0px;
      }
    }
    ion-footer {
      width: 400px;
      margin-left: auto;
      margin-right: auto;
      border-radius: 0px 0px 20px 20px;
      padding: 8px !important;
      ion-toolbar {
        padding: 8px !important;
        border-radius: 0px 0px 20px 20px;
        background: #131417;
      }
    }
    .toastCss {
      position: absolute;
      top: 50% !important;
      left: 50% !important;
      max-width: 380px;
      height: 100% !important;
      transform: translate(-50%, -50%);
    }
    .alertClass {
      width: 400px;
      margin: auto;
      justify-content: center;
      z-index: -1;
      border-radius: 0px 0px 20px 20px;
    }
    .alert-header {
      width: 400px;
      margin: auto;
      justify-content: center;
      z-index: -1;
      border-radius: 0px 0px 20px 20px;
    }
  }
  ion-modal {
    height: 100%;
    width: 100%;
    --ion-backdrop-color: transparent;
    ion-content {
      height: 100% !important;
      width: 100% !important;
      position: absolute;
      top: 0px !important;
      left: 0vw !important;
    }
  }
  .team-preview-modal {
    --height: 100% !important;
    width: 400px;
    margin: auto;
    justify-content: center;
    top: 0vh;
    --border-radius: 20px;
    border-radius: 20px;
  }
  .add-cash-modal,
  .withdraw-modal {
    --height: 100% !important;
    width: 400px;
    margin: auto;
    justify-content: center;
    top: 0vh;
    --border-radius: 20px;
    border-radius: 20px;
    ion-title {
      text-align: center !important;
      width: 86% !important;
    }
    .preview-back-button {
      right: 60px !important;
    }
  }
  .loadingCss {
    height: 100%;
    width: 400px;
    margin: auto;
    justify-content: center;
    border-radius: 20px;
  }
  .date-show {
    padding-left: 3vw;
  }
  .shadowContentBg {
    &::part(background) {
      border-radius: 20px;
    }
  }
  .picker-md .picker-wrapper {
    width: 400px;
  }
  .modal-wrapper.sc-ion-modal-md {
    box-shadow: none;
  }
  .playersContainer {
    margin-top: 160px;
  }
  .country-modal {
    width: 360px;
    height: 79%;
    margin-top: 10vh;
    ion-content {
      --padding-top: 0px;
      --padding-start: 20px;
    }
  }
}

@media (min-width: 1200px) {
  .bg-pwa {
    .inner-bg-pwa {
      margin: 30px 10px;
    }
    height: 100%;
    width: 100%;
    background-image: url("./assets/side_bg.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    ion-content {
      width: 27.5% !important;
      left: 10vw;
      top: -5px;
      .content {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
        box-sizing: content-box; /* So the width will be 100% + 17px */
        padding-top: 10px;
      }
    }
    ion-header {
      width: 27.5%;
      left: 10vw;
      position: relative;
      z-index: 99999;
      border-radius: 20px 20px 0px 0px;
      ion-toolbar {
        border-radius: 20px 20px 0px 0px;
      }
    }
    ion-footer {
      width: 27.5%;
      left: 10vw;
      z-index: -1;
      border-radius: 0px 0px 20px 20px;
      top: -8px;
      ion-toolbar {
        padding: 0px 0px;
        border-radius: 0px 0px 20px 20px;
        background: #131417;
      }
      .sec-row {
        margin: 0px 0px;
      }
    }
    .toastCss {
      left: 10vw;
    }
    .alertClass {
      width: 27.5%;
      left: 10vw;
      z-index: -1;
      border-radius: 0px 0px 20px 20px;
    }
    .alert-header {
      width: 27.5%;
      left: 10vw;
      z-index: -1;
      border-radius: 0px 0px 20px 20px;
    }
  }
  ion-modal {
    height: 100%;
    width: 100%;
    --ion-backdrop-color: transparent;
    ion-content {
      height: 100% !important;
      width: 100% !important;
      position: absolute;
      top: 0px !important;
      left: 0vw !important;
    }
  }
  .team-preview-modal {
    --height: 100% !important;
    width: 27.5%;
    left: 10vw;
    top: 0vh;
    --border-radius: 20px;
  }
  .add-cash-modal,
  .withdraw-modal {
    --height: 100% !important;
    width: 27.5%;
    left: 10vw;
    top: 0vh;
    --border-radius: 20px;
    ion-title {
      text-align: center !important;
      width: 86% !important;
    }
    .preview-back-button {
      right: 60px !important;
    }
  }
  .loadingCss {
    height: 100%;
    width: 27.5%;
    left: 10vw;
    border-radius: 20px;
  }
  .date-show {
    justify-content: flex-start;
    padding-left: 12.2vw;
  }
  .shadowContentBg {
    &::part(background) {
      border-radius: 20px;
    }
  }
  .picker-md .picker-wrapper {
    width: 27.5%;
    margin-left: 10vw;
  }
  .modal-wrapper.sc-ion-modal-md {
    box-shadow: none;
  }
  .country-modal {
    position: absolute;
    margin-left: 11.5vw;
    height: 81%;
    width: 25%;
    ion-header {
      position: absolute !important;
      left: 0vw !important;
    }
    .modal-wrapper.sc-ion-modal-md {
      position: absolute !important;
      left: 8.5vw !important;
    }
  }
  .playersContainer {
    margin-top: 265px;
  }
}

/************** Dashboard and My Matches Card Design **************/
.up-matches-card {
  height: auto;
  width: 90%;
  margin: 5% 5%;
  box-shadow: var(--outer-shadow);
  border-radius: 15px;
  padding: 0px;
  background: url(assets/images/card_bg_1.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #c4c4c44d;
  // .card_inner {
  //   background: #c4c4c417;
  // }
  .cont-color {
    font-weight: 700;
    font-size: 12px;
    line-height: 13px;
    text-align: center;
    letter-spacing: -0.289366px;
    color: #ffffff;
    margin-bottom: 0px;
    margin-left: 10px;
    margin-top: 3px;
  }
  .side-psy {
    padding: 0;
    height: 25px;
    background: linear-gradient(180deg, #262424 0%, #131313 100%);
    border: 1px solid #111111;
    margin-top: -1px;
    border-radius: 0px 3px 0px 3px;
    p {
      font-weight: 400 !important;
      font-size: 11px;
      line-height: 14px;
      text-align: center;
      color: #fff;
      border-radius: 10px;
      margin-bottom: 0px;
      margin-top: 0px;
      padding-top: 5px;
    }
  }
  .dis-fles {
    ion-col {
      display: flex;
      .team_abbr {
        border-radius: 4px;
        margin: auto;
        width: 100%;
        height: 30px;
        margin-top: 12px;
      }
      .team_left {
        background: url(assets/images/team_left.svg);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-right: -45px;
        margin-left: 15px;
        p {
          margin-left: -10px !important;
        }
      }
      .team_right {
        background: url(assets/images/team_right.svg);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-left: -45px;
        margin-right: 15px;

        z-index: 8;
        p {
          margin-left: 10px !important;
        }
      }
      p {
        font-weight: 700 !important;
        color: #ffffff;
        font-size: 11px !important;
        line-height: 25px;
        margin: auto;
        vertical-align: middle;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .vs_col {
      div {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: -4px;
      }
    }
    .teamimg_col {
      height: 45px;
      width: 50px;
      display: flex;
      margin: auto;
      z-index: 10;
      img {
        padding: 5px;
        background: #252323;
        box-shadow: inset -6px -6px 12px rgb(255 255 255 / 8%),
          inset 6px 6px 12px rgb(0 0 0 / 80%);
        border-radius: 30px;
        border: 1px solid #aded6d;
        box-shadow: 0px 0px 4px 1px #7fff00;
        width: 55px;
      }
    }
  }
  .teamname_row {
    .team_nm {
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      color: #ffffff;
      margin: 0px;
      align-items: center;
      display: flex;
      min-height: 30px;
    }
  }
}
@media (min-width: 0px) and (max-width: 320px) {
  ion-label,
  ion-title,
  p,
  ion-text,
  span,
  ion-input {
    font-size: 9px !important;
  }

  .img-menu {
    width: 35px;
    margin-bottom: -10px;
  }

  ion-button {
    font-size: 12px;
  }
}
@media (min-width: 0px) and (max-width: 360px) {
  .add-cash-modal .ion-grid {
    margin-top: 25%;
  }
  .withdraw-modal .ion-grid {
    margin-top: 30%;
  }
}

@media (min-width: 361px) {
  ion-label,
  ion-title,
  p,
  ion-text,
  span,
  ion-input {
    font-size: 11px !important;
  }
}
@media (min-width: 1200px) {
  ion-label,
  ion-title,
  p,
  ion-text,
  span,
  ion-input {
    font-size: 12.5px !important;
  }
}

@media (max-width: 320px) {
  .teamimg_col {
    height: 30px !important;
    width: 30px !important;
    margin-top: 10px;
  }
  .btn-create {
    width: 100% !important;
    margin: 0px;
    height: 40px;
    margin: 15px 2px;
  }
  .btn-team {
    width: 100% !important;
    margin: 0px;
    height: 40px;
    margin: 15px 2px;
  }
}
ion-footer {
  background: #131313 !important;
}
.content::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.content {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
//galaxy fold and nest hub device
@media (max-height: 512px) and (max-width: 718px),
  (max-height: 600px) and (max-width: 1024px) {
  .add-cash-modal,
  .withdraw-modal {
    ion-title {
      width: 62% !important;
    }
    .ion-grid {
      width: 70% !important;
      margin-top: 8% !important;
    }
    .preview-back-button {
      right: 140px !important;
    }
  }
}

ion-label,
ion-text,
ion-title,
p,
span {
  cursor: default;
}
@media (max-width: 1000px) and (min-height: 1100px) {
  .inner-bg-pwa {
    margin: 20% !important;
  }
}
@media (width: 1024px) and (height: 1366px),
  (width: 1024px) and (height: 1366.4px) {
  .inner-bg-pwa {
    margin: 20% !important;
  }
}

ion-footer {
  &.footer-md::before {
    background-image: none;
  }
}

@media (min-width: 500px) {
  ion-footer {
    .sec-row {
      margin: 0px 0px;
    }
  }
}
@media (width: 1024px) and (height: 600px) {
  .country-modal {
    width: 360px;
    height: 100%;
    margin-top: 0vh;
  }
}
@media(max-width:280px)
{
  .up-matches-card .dis-fles ion-col .team_left p {
    margin-left: -20px !important;
}
.up-matches-card .dis-fles ion-col .team_right p {
  margin-left: 20px !important;
}
.header-flex {
  margin: 0px 0px 0px 10px;
}
}
// ion-content {
//  --offset-bottom: auto!important;
//  --overflow: hidden;
//  overflow: auto;
//  &::-webkit-scrollbar {
//    display: none;
//  }
//  }
